<template>
  <v-container fluid>
    <dialprod
      ref="dialprod"
      :orderName="order.nameOrder"
      @prod="updateValue"
    ></dialprod>
    <reception
      @done="getDocument"
      v-show="status === 'reception'"
      :order="order.pk"
      :edit="edit"
    />
    <v-card
      v-if="
        status !== 'reception' ||
        discrepancies.length > 0 ||
        (status === 'finished' && discrepancies === 0)
      "
      class="infocard"
    >
      <v-card-title
        :class="{
          'primary--text': true,
          'font-weight-bold': true,
          headline: !$vuetify.breakpoint.xsOnly,
          title: $vuetify.breakpoint.xsOnly,
          'pb-1': true
        }"
      >
        <v-tooltip bottom v-if="status === 'pdocument'">
          <template v-slot:activator="{ on }">
            <i-btn
              v-if="changeStatus.edit"
              v-on="on"
              :title="$t('send_purchase_order')"
              outlined
              classes="mx-1"
              icon="fa-paper-plane"
              color="success"
              @click="update('send')"
            />
          </template>
        </v-tooltip>
        <v-tooltip
          bottom
          v-if="status === 'pdocument' || status === 'sended_document'"
        >
          <template v-slot:activator="{ on }">
            <i-btn
              v-if="changeStatus.edit"
              v-on="on"
              :title="$t('aproved_purchase_order')"
              outlined
              classes="mx-1"
              icon="fa-check"
              color="success"
              @click="update('approved')"
            />
          </template>
        </v-tooltip>
        <v-tooltip bottom v-if="status !== 'reception'">
          <template v-slot:activator="{ on }">
            <i-btn
              v-on="on"
              :title="$t('download', { value: $t('pdocument') })"
              outlined
              classes="mx-1"
              icon="fa-file-download"
              color="error"
              @click="getPdf(true)"
            />
            <i-btn
              v-on="on"
              :title="$t('print', { value: $t('pdocument') })"
              outlined
              classes="mx-1"
              icon="fa-file-pdf"
              color="error"
              @click="getPdf()"
            />
          </template>
        </v-tooltip>
        <v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <i-btn
              v-if="status === 'pdocument' && orderProductBtn.add"
              v-on="on"
              :title="`${$t('add', { value: $tc('product', 2) })}`"
              outlined
              classes="mx-1"
              icon="fa-cart-plus"
              color="info"
              @click="$emit('addProd')"
            />
            <i-btn
              v-if="status === 'in_process' && changeStatus.edit"
              v-on="on"
              :title="$t('received_purchase_order', 1)"
              outlined
              classes="mx-1"
              icon="fa-check-double"
              color="success"
              @click="update('received')"
            />
          </template>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <i-btn
              v-if="status === 'reception'"
              v-on="on"
              :title="$t('download', { value: $t('discrepancynote') })"
              outlined
              classes="mx-1"
              icon="fa-file-download"
              color="error"
              @click="getDNPdf(true)"
            />
            <i-btn
              v-if="status === 'reception'"
              v-on="on"
              :title="$t('print', { value: $t('discrepancynote') })"
              outlined
              classes="mx-1"
              icon="fa-file-pdf"
              color="error"
              @click="getPdf()"
            />
          </template>
        </v-tooltip>
        <v-icon v-text="'icon-request'" class="primary--text mr-3" />
        <span v-if="status === 'reception'">
          {{ $t('discrepancynote') }} # {{ pdocument.code }}
        </span>
        <span v-else-if="status !== 'reception'">
          {{ $t('pdocument') }} # {{ pdocument.code }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col
            cols="12"
            :class="{
              'pr-3': $vuetify.breakpoint.smAndUp
            }"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              name="name_infoSupplier"
              disabled
              :value="infoSupplier.company"
              readonly
              :label="$tc('supplierCompany', 1)"
            />
          </v-col>
          <v-col
            cols="12"
            :class="{
              'pr-3': $vuetify.breakpoint.smAndUp
            }"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              name="contact"
              disabled
              :value="`${infoSupplier.first_name} ${infoSupplier.last_name}`"
              readonly
              :label="$tc('contact', 1)"
            />
          </v-col>
          <v-col
            cols="12"
            :class="{
              'pr-3': $vuetify.breakpoint.smAndUp
            }"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              name="phone"
              disabled
              :value="`${infoSupplier.phone}`"
              readonly
              :label="$tc('phone', 1)"
            />
          </v-col>
          <v-col
            cols="12"
            :class="{
              'pr-3': $vuetify.breakpoint.smAndUp
            }"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              name="email"
              disabled
              :value="`${infoSupplier.email}`"
              readonly
              :label="$tc('email', 1)"
            />
          </v-col>
          <v-col
            cols="12"
            :class="{
              'pr-3': $vuetify.breakpoint.smAndUp
            }"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              name="date"
              disabled
              :value="dateFormat(order.date)"
              prepend-icon="fa-calendar"
              readonly
              :label="$tc('date', 1)"
            />
          </v-col>
          <v-col
            cols="12"
            :class="{
              'pr-3': $vuetify.breakpoint.smAndUp
            }"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              name="expected_date"
              disabled
              :value="dateFormat(order.expected_date)"
              prepend-icon="fa-calendar"
              readonly
              :label="$tc('expected_date', 1)"
            />
          </v-col>
          <v-col
            cols="12"
            :class="{
              'pr-3': $vuetify.breakpoint.smAndUp
            }"
            sm="6"
            md="4"
            lg="3"
            v-for="(address, inx) in infoSupplier.address_info"
            :key="`address-1-${inx}`"
          >
            <v-text-field
              :name="
                address.address_type !== 'DISP'
                  ? 'supplierAddress'
                  : 'dispatchAddress'
              "
              disabled
              :value="getAddressName(address)"
              readonly
              :label="
                $tc(
                  address.address_type !== 'DISP'
                    ? 'supplierAddress'
                    : 'dispatchAddress',
                  1
                )
              "
            />
          </v-col>
          <v-col
            cols="12"
            :class="{
              'pr-3': $vuetify.breakpoint.smAndUp
            }"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              name="purchasesReceiver"
              disabled
              :value="`${order.name_purchasesReceiver}`"
              readonly
              :label="$tc('purchasesReceiver', 1)"
            />
          </v-col>
          <v-col
            cols="12"
            :class="{
              'pr-3': $vuetify.breakpoint.smAndUp
            }"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              name="order_name"
              disabled
              :value="`${order.nameOrder}`"
              readonly
              :label="$tc('order_name', 1)"
            />
          </v-col>
        </v-row>
        <reception
          @done="getDocument"
          v-show="status === 'reception'"
          :order="order.pk"
          :edit="!edit"
        />
        <!-- products list -->
        <v-col
          align="center"
          justify="center"
          class="display-1 font-weight-black grey--text"
          cols="12"
        >
          {{
            cartlist.length > 0 ? '' : $vuetify.lang.t('$vuetify.noDataText')
          }}
        </v-col>
        <v-list dense v-if="status !== 'reception'">
          <v-list-group
            v-for="(product, key) in cartlist"
            :key="`prouct-${key}`"
            :style="{
              'border-top':
                key !== 0
                  ? ''
                  : `2px solid ${$vuetify.theme.themes.light.primary}`,
              'border-bottom': `2px solid ${$vuetify.theme.themes.light.primary}`,
              'border-left': `2px solid ${$vuetify.theme.themes.light.primary}`,
              'border-right': `2px solid ${$vuetify.theme.themes.light.primary}`
            }"
            @click="searchProductInfo(product.pk)"
          >
            <v-icon slot="appendIcon" class="white--text" size="12"
              >fas fa-arrow-down</v-icon
            >
            <template v-slot:activator>
              <v-list-item-content>
                <v-row>
                  <v-col cols="6" sm="4" lg="3" v-if="product.code !== ''">
                    <span class="primary--text body-2 font-weight-black">
                      {{ $tc('code', 2) }} : <br />
                    </span>
                    <span class="primary--text body-2 front-weight-black">
                      {{ product.code }}
                    </span>
                  </v-col>
                  <v-col cols="6" sm="4" lg="3">
                    <span class="primary--text body-2 font-weight-black">
                      {{ $tc('subname', 2) }} : <br />
                    </span>
                    <span class="primary--text body-2 front-weight-black">
                      {{ product.name }}
                    </span>
                  </v-col>
                  <v-col cols="6" sm="4" lg="3">
                    <span class="primary--text body-2 font-weight-black">
                      {{ $tc('product', 1) }} : <br />
                    </span>
                    <span class="primary--text body-2 front-weight-black">
                      {{ product.own_prod }}
                    </span>
                  </v-col>
                  <v-col cols="6" sm="4" lg="3">
                    <span class="primary--text body-2 font-weight-black">
                      {{ $tc('amount', 2) }} : <br />
                    </span>
                    <span class="primary--text body-2 front-weight-black">
                      {{ product.quantity }}
                    </span>
                  </v-col>
                  <v-col cols="6" sm="4" lg="3">
                    <span class="primary--text body-2 font-weight-black">
                      {{ $tc('price', 1) }} : <br />
                    </span>
                    <span class="primary--text body-2 front-weight-black">
                      <i-money
                        :value="
                          Number(product.total) / Number(product.quantity)
                        "
                        mode="text"
                      />
                    </span>
                  </v-col>
                  <v-col cols="6" sm="4" lg="3">
                    <span class="primary--text body-2 font-weight-black">
                      {{ $tc('subtotal', 1) }} : <br />
                    </span>
                    <span class="primary--text body-2 front-weight-black">
                      <i-money :value="Number(product.subtotal)" mode="text" />
                    </span>
                  </v-col>
                  <v-col cols="6" sm="4" lg="3">
                    <span class="primary--text body-2 font-weight-black">
                      {{ $tc('tax', 1) }} : <br />
                    </span>
                    <span class="primary--text body-2 front-weight-black">
                      <i-money v-model="product.tax" mode="text" />
                    </span>
                  </v-col>
                  <v-col cols="6" sm="4" lg="3">
                    <span class="primary--text body-2 font-weight-black">
                      {{ $tc('total', 1) }} : <br />
                    </span>
                    <span class="primary--text body-2 front-weight-black">
                      <i-money v-model="product.total" mode="text" />
                    </span>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="4"
                    lg="3"
                    v-if="
                      status === 'pdocument' &&
                      pdocument.state !== 'approved' &&
                      pdocument.state !== 'received'
                    "
                  >
                    <i-btn
                      v-if="orderProductBtn.edit"
                      :title="$tc('edit', 1)"
                      outlined
                      classes="mx-1"
                      icon="mdi-pencil"
                      color="info"
                      @click.stop="$refs.dialprod.open(product.pk)"
                    />
                    <i-btn
                      v-if="orderProductBtn.delete"
                      :title="$tc('delete', 1)"
                      outlined
                      classes="mx-1"
                      icon="fa-trash"
                      color="error"
                      @click.stop="remove(product.pk)"
                    />
                  </v-col>
                </v-row>
              </v-list-item-content>
            </template>
            <!-- init extra information -->
            <v-row class="white mx-0 px-1">
              <v-col
                cols="12"
                sm="6"
                md="4"
                v-show="product.description !== ''"
              >
                <v-subheader class="pl-0 primary--text font-weight-bold">
                  {{ $t('description') }}
                </v-subheader>
                <div class="infocard pa-1" v-html="product.description"></div>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                v-show="product.additional_side !== ''"
              >
                <v-subheader class="pl-0 primary--text font-weight-bold">
                  {{ $t('additional_side') }}
                </v-subheader>
                <div
                  class="infocard pa-1"
                  v-html="product.additional_side"
                ></div>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                v-if="
                  informationExtra[product.pk] === undefined ||
                  informationExtra[product.pk]['dates'] === undefined ||
                  (informationExtra[product.pk] !== undefined &&
                    informationExtra[product.pk]['dates'] !== undefined &&
                    informationExtra[product.pk]['dates'].length > 0)
                "
              >
                <v-subheader class="pl-0 primary--text font-weight-bold">
                  {{ $t('dates') }}
                </v-subheader>
                <div
                  v-if="
                    informationExtra[product.pk] !== undefined &&
                    informationExtra[product.pk]['dates'] !== undefined
                  "
                >
                  <div
                    class="infocard px-1 my-1"
                    v-for="(date, keydate) in informationExtra[product.pk][
                      'dates'
                    ].filter((m) => !m.changeDate)"
                    :key="'dates-' + keydate"
                    style="display: flex; justify-content: space-between"
                  >
                    <span class="font-weight-black">{{ date.typeName }}</span>
                    <span>
                      {{ dateFormat(date.date) }}
                    </span>
                  </div>
                </div>
                <v-skeleton-loader v-else type="text" tile> </v-skeleton-loader>
              </v-col>
            </v-row>
            <!-- end extra information -->
          </v-list-group>
        </v-list>
        <v-row
          no-gutters
          class="text-right body-2 font-weight-black primary--text pr-4"
          v-if="status !== 'reception'"
        >
          <v-col cols="12">
            <i-money
              mode="text"
              :value="Number(order.subtotal)"
              :label="`${$t('subtotal')}:`"
            />
          </v-col>
          <v-col cols="12">
            <i-money
              mode="text"
              :label="$t('tax')"
              :value="order.tax"
              class="font-weight-black"
            />
          </v-col>
          <v-col cols="12">
            <i-money
              class="headline font-weight-black"
              mode="text"
              :value="order.total"
              :label="`${$t('total')}:`"
            />
          </v-col>
        </v-row>
        <!-- end products list -->
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import dialprod from '../purchase/DialogProduct.vue'
import reception from './orderProduct.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    dialprod,
    reception
  },
  props: {
    order: {
      required: true,
      type: [Object]
    },
    infoSupplier: {
      required: false,
      type: [Object]
    },
    status: {
      type: String
    },
    cartlist: [Array, Object]
  },
  data() {
    return {
      selectedItem: null,
      informationExtra: {},
      edit: true,
      empty: true,
      pdocument: {
        pk: '',
        code: '',
        products: {},
        state: '',
        date: '',
        mail_date: null,
        infoSupplier: null,
        purchasesReceiver: null,
        order: null
      },
      mode: null,
      pkRemove: null,
      discrepancies: [],
      load: false
    }
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    orderProductBtn() {
      return {
        add:
          (this.getPermissions([`purchase.add_purchaseorderproduct`]) &&
            this.getPermissions([`purchase.change_purchasedocument`])) ||
          this.isAdmin,
        edit:
          (this.getPermissions([`purchase.change_purchaseorderproduct`]) &&
            this.getPermissions([`purchase.change_purchasedocument`])) ||
          this.isAdmin,
        delete:
          (this.getPermissions([`purchase.delete_purchaseorderproduct`]) &&
            this.getPermissions([`purchase.change_purchasedocument`])) ||
          this.isAdmin
      }
    },
    changeStatus() {
      return {
        edit:
          (this.getPermissions([`purchase.change_purchaseorderstatus`]) &&
            this.getPermissions([`purchase.change_purchasedocument`])) ||
          this.isAdmin
      }
    }
  },
  methods: {
    ...mapActions({
      showConfirmation: 'confirmation/confirmationValue'
    }),
    remove(pk) {
      this.pkRemove = pk
      this.showConfirmation({
        show: true,
        model: this.$tc('product', 1),
        title: this.$tc('delete', 1),
        type: 'error white--text',
        content: this.$t('confirm_delete'),
        alert: `${this.$t('success', {
          model: this.$tc('product', 1),
          action: this.$tc('delete', 2)
        })}`,
        confirm: () => this.$api.purchase.product.remove({ pk })
      })
    },
    getPdf(down) {
      this.downloadFile({
        api: 'purchaseDocument',
        method: 'DNpdf',
        filename: 'PurchaseOrder',
        action: down ? 'download' : 'window',
        ext: 'pdf',
        opt: { pk: this.pdocument.order }
      })
    },
    getDNPdf(down) {
      this.downloadFile({
        api: 'purchaseDocument',
        method: 'DNpdf',
        filename: 'DiscrepancyNote',
        action: down ? 'download' : 'window',
        ext: 'pdf',
        opt: { pk: this.pdocument.order }
      })
    },
    getAddressName(address) {
      let name = `${address.address1}, `
      name += address.address2 != '' ? `${address.address2}, ` : ''
      name += `${address.city}, ${address.state}`
      return name
    },
    chekdimensions(item) {
      return Object.keys(item).length > 0
    },
    searchProductInfo(id) {
      if (this.informationExtra[id] === undefined) {
        try {
          this.load = true
          this.$api.suborder.date
            .show({
              pk: id,
              opt: {
                params: {
                  order: true
                }
              }
            })
            .then((response) => {
              this.$set(this.informationExtra, id, {
                ...this.informationExtra[id],
                dates: response.data
              })
            })
          this.$api.suborder.production
            .show({
              pk: id,
              opt: {
                params: {
                  subOrder: true
                }
              }
            })
            .then((response) => {
              this.$set(this.informationExtra, id, {
                ...this.informationExtra[id],
                details: response.data
              })
            })
        } finally {
          this.load = false
        }
      }
    },
    update(mode) {
      this.mode = mode
      let state = ''
      let title = ''
      switch (mode) {
        case 'send':
          state = 'sended_document'
          title = this.$tc('send', 1)
          break
        case 'approved':
          state = 'approved'
          title = this.$t('approve')
          break
        case 'reception':
          state = 'received'
          title = this.$t('reception')
          break
        case 'finished':
          state = 'finished'
          title = this.$t('finished')
          break
      }
      this.showConfirmation({
        show: true,
        model: this.$tc('pdocument', 1),
        title: title,
        type: 'warning white--text',
        content: this.$t('areSure', {
          action: title,
          model: this.$tc('pdocument', 1)
        }),
        alert: `${this.$t('success', {
          model: this.$tc('pdocument', 1),
          action: this.$tc('update', 2)
        })}`,
        confirm: () => this.editDocument(state)
      })
    },
    editDocument(state) {
      this.pdocument.state = state
      this.$api.purchaseDocument.edit({
        pk: this.pdocument.pk,
        form: this.pdocument
      })
      this.$emit('done')
    },
    async getDocument() {
      if (
        this.pdocument.order !== null &&
        this.pdocument.infoSupplier !== null
      ) {
        let d = new Date()
        this.pdocument.date = `${d.getFullYear()}-${(
          '0' +
          (d.getMonth() + 1)
        ).slice(-2)}-${('0' + d.getDate()).slice(-2)}`
        const purchaseDocument = await this.$api.purchaseDocument.create({
          form: this.pdocument
        })
        this.pdocument = purchaseDocument.data
        const discrepancy = await this.$api.purchase.discrepancy.show({
          pk: this.order.pk,
          opt: {
            params: {
              order: true
            }
          }
        })
        this.discrepancies = discrepancy.data.discrepancy
        if (this.discrepancies.length == 0 && this.status == 'reception') {
          this.$emit('update:status', 'finished')
        }
      }
    },
    updateValue(evt) {
      this.$emit(
        'update:cartlist',
        this.cartlist.map((m) => {
          if (m.pk === evt.pk) {
            return { ...evt, updateProduct: true }
          }
          return m
        })
      )
    }
  },
  watch: {
    status: {
      immediate: true,
      handler() {
        //
      }
    },
    order: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val !== undefined && val !== null) {
          if (this.pdocument.order !== val.pk) {
            this.pdocument.order = val.pk
          }
          if (this.pdocument.pk === '' || this.pdocument.pk === null) {
            this.getDocument()
          }
        }
      }
    },
    infoSupplier: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val !== undefined && val !== null) {
          if (this.pdocument.infoSupplier !== val.pk) {
            this.pdocument.infoSupplier = val.pk
          }
          if (this.pdocument.pk === '' || this.pdocument.pk === null) {
            this.getDocument()
          }
        }
      }
    },
    cartlist: {
      immediate: true,
      deep: true,
      handler(val) {
        for (let x in val) {
          if (val[x]['updateProduct'] !== undefined) {
            delete this.informationExtra[val[x]['pk']]
            this.searchProductInfo(val[x]['pk'])
          }
        }
        let subtotal = this.cartlist
          .reduce(
            (accumulator, currentValue) =>
              accumulator + Number(currentValue.subtotal),
            0
          )
          .toFixed(2)
        let tax = this.cartlist
          .reduce(
            (accumulator, currentValue) =>
              accumulator + Number(currentValue.tax),
            0
          )
          .toFixed(2)
        let total = (Number(subtotal) + Number(tax)).toFixed(2)
        this.$emit('subtotalOrder', subtotal)
        this.$emit('taxOrder', tax)
        this.$emit('totalOrder', total)
      }
    }
  },
  created() {
    this.unwatch = this.$store.watch(
      () => this.$store.getters['confirmation/getConfirmationFinished'],
      async (val) => {
        if (val === this.$tc('product', 1) && this.pkRemove !== null) {
          this.$store.dispatch('confirmation/confirmationFinished', false)
          this.$emit(
            'update:cartlist',
            this.cartlist.filter((m) => m.pk !== this.pkRemove)
          )
          this.pkRemove = null
        }
        if (val === this.$tc('pdocument', 1) && this.mode !== null) {
          switch (this.mode) {
            case 'send':
              this.pdocument.state = 'sended_document'
              this.$emit('update:status', 'sended_document')
              break
            case 'approved':
              this.pdocument.state = 'approved'
              this.$emit('update:status', 'in_process')
              break
            case 'received':
              this.pdocument.state = 'received'
              this.$emit('update:status', 'reception')
              break
            case 'finished':
              this.pdocument.state = 'finished'
              this.$emit('update:status', 'finished')
              break
          }
          this.editDocument()
          this.mode = null
        }
      }
    )
  },
  beforeDestroy() {
    this.unwatch()
  }
}
</script>
