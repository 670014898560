<template>
  <v-row v-if="receptionBtn.view">
    <v-col>
      <dialreception
        v-model="dialog"
        @done="getReceptions"
        :orderProduct="orderProduct"
        :draft="draft"
      />
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <i-btn
            v-if="receptionBtn.add"
            :title="$t('create', { model: $tc('reception', 1) })"
            outlined
            v-on="on"
            :small="$vuetify.breakpoint.smAndDown"
            color="secondary"
            icon="fa-plus"
            @click="open()"
          />
        </template>
        {{ $t('create', { model: $tc('reception', 1) }) }}
      </v-tooltip>
    </v-col>
    <v-col cols="9" class="pt-0 ml-1 pb-3">
      <span
        :class="{
          title: !$vuetify.breakpoint.xsOnly,
          'subtitle-1': $vuetify.breakpoint.xsOnly,
          'font-weight-black': true,
          'grey--text': true
        }"
      >
        {{ $t('reception') }}
      </span>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="3"
      v-for="x in [1, 2, 3, 4]"
      v-show="loading"
      :key="'skeleton-' + x"
    >
      <v-skeleton-loader type="article" class="mx-auto"></v-skeleton-loader>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="4"
      v-for="item in receptions"
      :key="'reception-' + item.pk"
    >
      <v-card :class="{ 'elevation-0': true, listcard: true }">
        <v-card-text>
          <span class="primary--text font-weight-black">{{
            `${$t('received_quantity')}: `
          }}</span>
          <span class="primary--text">{{ `${item.received_quantity}` }}</span>
          <br />
          <span class="primary--text font-weight-black">{{
            `${$t('received_date')}: `
          }}</span>
          <span class="primary--text">{{ `${item.received_date}` }}</span>
          <br />
          <v-col v-if="receptionBtn.delete" class="pa-0 ma-0" align="end">
            <v-btn text color="error" @click.stop="remove(item.pk)">
              <v-icon v-text="'fa-trash'" />
            </v-btn>
          </v-col>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import dialreception from './receptionCreate.vue'
export default {
  components: {
    dialreception
  },
  props: {
    orderProduct: {
      type: [Array, Object]
    }
  },
  data() {
    return {
      receptions: [],
      dialog: false,
      load: false,
      isLoading: false,
      loading: false,
      draft: 0
    }
  },
  watch: {
    orderProduct: {
      immediate: true,
      handler(val) {
        if (val !== undefined) {
          this.getReceptions()
        }
      }
    }
  },
  mounted() {
    this.getReceptions()
  },
  created() {
    this.unwatch = this.$store.watch(
      () => this.$store.getters['confirmation/getConfirmationFinished'],
      async (val) => {
        if (val === this.title) {
          await this.getReceptions()
          this.$emit('updateReceptions')
          this.$store.dispatch('confirmation/confirmationFinished', false)
        }
      }
    )
  },
  beforeDestroy() {
    this.unwatch()
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    receptionBtn() {
      return {
        add: this.getPermissions([`purchase.add_reception`]) || this.isAdmin,
        delete:
          this.getPermissions([`purchase.delete_reception`]) || this.isAdmin,
        view: this.getPermissions([`purchase.view_reception`]) || this.isAdmin
      }
    }
  },
  methods: {
    ...mapActions({
      showConfirmation: 'confirmation/confirmationValue'
    }),
    open() {
      this.dialog = true
    },
    async getReceptions() {
      this.isLoading = true
      try {
        let response = await this.$api.purchase.reception.show({
          pk: this.orderProduct.pk,
          opt: {
            params: {
              orderProduct: true
            }
          }
        })
        this.receptions = response.data
      } finally {
        this.isLoading = false
      }
    },
    async remove(pk) {
      try {
        this.load = true
        const response = await this.$api.purchase.reception.show({ pk })
        this.draft = Number(response.data.received_quantity) * -1
      } finally {
        this.load = true
      }
      this.showConfirmation({
        show: true,
        model: this.title,
        title: this.$tc('delete', 1),
        type: 'error white--text',
        content: this.$t('confirm_delete'),
        alert: `${this.$t('success', {
          model: this.title,
          action: this.$tc('delete', 2)
        })}`,
        confirm: () => this.$api.purchase.reception.remove({ pk })
      })
    }
  }
}
</script>
