<template>
  <v-container fluid>
    <v-list v-for="(product, key) in orderProduct" :key="`product-${key}`">
      <template>
        <v-list
          v-if="
            (edit && orderProduct[(product, key)].pending_quantity < 0.01) ||
            (!edit && orderProduct[(product, key)].pending_quantity > 0)
          "
          :style="{
            'border-top': `2px solid ${$vuetify.theme.themes.light.primary}`,
            'border-bottom': `2px solid ${$vuetify.theme.themes.light.primary}`,
            'border-left': `2px solid ${$vuetify.theme.themes.light.primary}`,
            'border-right': `2px solid ${$vuetify.theme.themes.light.primary}`
          }"
        >
          <v-col cols="12">
            <v-list dense class="py-0">
              <v-list-group @click.stop="getOrderProduct()">
                <v-icon slot="appendIcon" size="12">fas fa-arrow-down</v-icon>
                <template v-slot:activator>
                  <v-row>
                    <span
                      v-if="edit"
                      class="primary--text body-2 font-weight-black"
                    >
                      {{ $t('finished') }}:
                    </span>
                    <v-col cols="3" sm="2" lg="2">
                      <span class="primary--text body-2 font-weight-black">
                        {{ $tc('order', 1) }} : <br />
                      </span>
                      <span class="primary--text body-2 front-weight-black">
                        {{ orderProduct[(product, key)].name }}
                      </span>
                    </v-col>
                    <v-col cols="3" sm="3" lg="3">
                      <span class="primary--text body-2 font-weight-black">
                        {{ $tc('product', 1) }} : <br />
                      </span>
                      <span class="primary--text body-2 front-weight-black">
                        {{ orderProduct[(product, key)].own_prod }}
                      </span>
                    </v-col>
                    <v-col cols="2" sm="2" lg="2">
                      <span class="primary--text body-2 font-weight-black">
                        {{ $tc('order_quantity', 1) }} <br />
                      </span>
                      <span class="primary--text body-2 front-weight-black">
                        {{
                          Number(orderProduct[(product, key)].quantity).toFixed(
                            2
                          )
                        }}
                      </span>
                    </v-col>
                    <v-col cols="2" sm="2" lg="2">
                      <span class="primary--text body-2 font-weight-black">
                        {{ $tc('pending_quantity', 1) }} : <br />
                      </span>
                      <span class="primary--text body-2 front-weight-black">
                        {{
                          Number(
                            orderProduct[(product, key)].pending_quantity
                          ).toFixed(2)
                        }}
                      </span>
                    </v-col>
                    <v-col cols="2" sm="2" lg="2">
                      <span class="primary--text body-2 font-weight-black">
                        {{ $tc('received_date', 1) }} : <br />
                      </span>
                      <span class="primary--text body-2 front-weight-black">
                        {{ orderProduct[(product, key)].received_date }}
                      </span>
                    </v-col>
                  </v-row>
                </template>
                <br />
                <receptionList :orderProduct="orderProduct[(product, key)]" />
              </v-list-group>
            </v-list>
            <br />
          </v-col>
        </v-list>
      </template>
    </v-list>
  </v-container>
</template>
<script>
import receptionList from './receptionList.vue'
export default {
  components: {
    receptionList
  },
  props: {
    status: {
      type: String
    },
    order: {
      required: true,
      type: Number
    },
    edit: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      reload: false,
      dialog: false,
      toEdit: null,
      load: false,
      orderProduct: []
    }
  },
  methods: {
    async getOrderProduct() {
      const response = await this.$api.purchase.product.show({
        pk: this.order,
        opt: {
          params: {
            order: true
          }
        }
      })
      this.$emit('done', response.data)
      this.orderProduct = response.data
    }
  },
  mounted() {
    this.getOrderProduct()
  }
}
</script>
