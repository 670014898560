<template>
  <i-modal
    :title="$t('add', { value: $tc('reception', 1) })"
    :value="value"
    :load="loading"
    @submit="submit"
    @input="$emit('input', $event)"
  >
    <v-row>
      <v-col cols="12">
        <ValidationProvider
          vid="received_quantity"
          :name="$tc('received_quantity', 1)"
          :rules="{
            required: true,
            max_value: Number(orderProduct.quantity)
          }"
          v-slot="{ errors }"
        >
          <v-text-field
            autofocus
            :error-messages="errors[0]"
            v-model="reception.received_quantity"
            outlined
            color="secondary"
            :label="$tc('received_quantity', 1)"
          />
        </ValidationProvider>
      </v-col>
    </v-row>
  </i-modal>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    orderProduct: {
      type: [Array, Object]
    },
    draft: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      draftFunction: false,
      reception: {
        received_quantity: 0,
        pending_quantity: 0,
        received_date: this.$moment().format('YYYY-MM-DD'),
        total: 0,
        price: 0,
        subtotal: 0,
        quantity: 0,
        product: null,
        orderProduct: this.orderProduct.pk
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.reception = {
            received_quantity: 0,
            pending_quantity: 0,
            received_date: this.$moment().format('YYYY-MM-DD'),
            total: 0,
            price: 0,
            subtotal: 0,
            quantity: 0,
            product: null,
            orderProduct: this.orderProduct.pk
          }
        }
      }
    },
    draft: {
      immediate: true,
      handler(val) {
        if (val) {
          this.reception.received_quantity = val
          this.submit()
        }
      }
    }
  },
  methods: {
    async submit() {
      if (!this.loading) {
        try {
          this.loading = true
          if (this.reception.received_quantity > 0) {
            const receptionResponse = await this.$api.purchase.reception.create(
              {
                form: this.reception
              }
            )
            this.$emit('done', receptionResponse.data)
            this.$toast.success(
              `${this.$tc('reception', 1)} ${this.$tc('created', 1)}`
            )
          }
          this.reception.total = this.orderProduct.total
          this.reception.price = this.orderProduct.price
          this.reception.subtotal = this.orderProduct.subtotal
          this.reception.quantity = this.orderProduct.quantity
          this.reception.product = this.orderProduct.product
          const orderProductResponse = await this.$api.purchase.product.edit({
            pk: this.orderProduct.pk,
            form: this.reception
          })
          this.$emit('input', false)
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>
