<template>
  <v-btn
    v-if="!value && shoplistBtn.view"
    class="shoplist--fixed-button"
    fab
    color="primary"
    @click="$emit('input', true)"
  >
    <v-icon v-text="'fa fa-shopping-cart'" />
  </v-btn>
  <v-card v-else class="shoplist--card">
    <v-card-text v-if="shoplistBtn.view" class="p-3">
      <!-- Título -->
      <v-card-title class="shoplist--title">
        <div>
          <v-icon class="primary--text mr-1">fa fa-shopping-cart</v-icon>
          {{ $t('shop_list') }}
        </div>
        <v-btn icon :title="$t('close')" @click="$emit('input', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <!-- Productos -->
      <v-card-text v-show="products.length > 0" class="shoplist--text">
        <!-- Títulos -->
        <div class="shoplist--tags">
          <span>{{ $t('name') }}</span>
          <span class="text-right">{{ $tc('amount', 2) }}</span>
          <span class="text-right">{{ $tc('price', 1) }}</span>
          <span></span>
        </div>

        <!-- Contenido -->
        <div class="shoplist--content">
          <div
            v-for="(prod, key) in products"
            :key="`${prod.internal}-${key}`"
            class="shoplist--content-item"
            @click="shoplistBtn.edit ? dialSubOrder(prod, type) : ''"
          >
            <!-- Nombre -->
            <div class="text-left">
              <span class="shoplist--content-tags">{{ $t('name') }}:</span>
              <span class="caption font-weight-black secondary--text">
                {{ prod.own_prod }}
              </span>
            </div>

            <!-- Cantidad -->
            <div>
              <span class="shoplist--content-tags">
                {{ $tc('amount', 2) }}:
              </span>
              <span>{{ prod.quantity }}</span>
            </div>

            <!-- Precios -->
            <div>
              <span class="shoplist--content-tags">
                {{ $tc('price', 1) }}:
              </span>
              <i-money mode="text" :value="prod.subtotal" />
            </div>

            <!-- Borrar -->
            <div>
              <i-btn
                v-if="
                  shoplistBtn.delete &&
                  status != 'canceled' &&
                  status != 'finished' &&
                  status != 'in_process'
                "
                :title="$tc('delete', 1)"
                outlined
                small
                classes="mx-1"
                icon="fa fa-trash"
                color="error"
                @click.stop="remove(prod.pk)"
              />
            </div>
          </div>
        </div>
      </v-card-text>

      <!-- Subtotal, impuesto y total -->
      <v-row
        class="text-right body-2 font-weight-black primary--text"
        no-gutters
      >
        <v-col cols="12">
          <i-money
            mode="text"
            :value="subtotal"
            :label="`${$t('subtotal')}:`"
          />
        </v-col>
        <v-col cols="12">
          <i-money mode="text" :label="$t('tax')" :value="tax" />
        </v-col>
        <v-col cols="12">
          <i-money mode="text" :value="total" :label="`${$t('total')}:`" />
        </v-col>
      </v-row>
    </v-card-text>
    <dialprodsale
      v-if="type === 'sale'"
      ref="dialprodsale"
      :customerTax="companyTax"
      :orderName="orderName"
      @prod="getOrderProduct"
    ></dialprodsale>
    <dialprodpurchase
      v-if="type !== 'sale'"
      ref="dialprodpurchase"
      :infoSupplierTax="companyTax"
      :orderName="orderName"
      :orderImage="orderImage"
      @prod="getOrderProduct"
    ></dialprodpurchase>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import dialprodpurchase from '../purchase/DialogProduct.vue'
import dialprodsale from '../sale/DialogProduct.vue'
export default {
  name: 'shoplist',
  props: {
    value: Boolean,
    order: {
      required: true,
      type: [Number, String]
    },
    status: {
      type: String
    },
    orderName: [String],
    orderImage: [File, String],
    type_customer: {
      required: false,
      type: Number
    },
    companyTax: {
      default: false,
      type: Boolean
    },
    subtotalOrder: [Number, String],
    totalOrder: [Number, String],
    taxOrder: [Number, String],
    cartlist: [Array, Object],
    type: {
      type: String
    }
  },
  components: {
    dialprodpurchase,
    dialprodsale
  },
  data() {
    return {
      products: [],
      total: 0,
      tax: 0,
      subtotal: 0,
      loading: true,
      deleting: false,
      updateOwn: false,
      reload: false
    }
  },
  created() {
    this.unwatch = this.$store.watch(
      () => this.$store.getters['confirmation/getConfirmationFinished'],
      async (val) => {
        if (val === this.$t('shop_list')) {
          this.getOrderProduct()
          this.$store.dispatch('confirmation/confirmationFinished', false)
        }
      }
    )
  },
  beforeDestroy() {
    this.unwatch()
  },
  computed: {
    ...mapGetters({
      getCreateSuborder: 'utils/getCreateSuborder',
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    shoplistBtn() {
      return {
        edit:
          this.type === 'sale'
            ? (this.getPermissions([`sales.change_orderproduct`]) &&
                this.getPermissions([`sales.view_orderstatus`]) &&
                this.getPermissions([`product.view_productiondetails`])) ||
              this.isAdmin
            : (this.getPermissions([`purchase.change_purchaseorderproduct`]) &&
                this.getPermissions([`purchase.view_purchaseorderstatus`]) &&
                this.getPermissions([`product.view_productiondetails`])) ||
              this.isAdmin,
        delete:
          this.type === 'sale'
            ? this.getPermissions([`sales.delete_orderproduct`]) || this.isAdmin
            : this.getPermissions([`purchase.delete_purchaseorderproduct`]) ||
              this.isAdmin,
        view:
          this.type === 'sale'
            ? this.getPermissions([`sales.view_orderproduct`]) || this.isAdmin
            : this.getPermissions([`purchase.view_purchaseorderproduct`]) ||
              this.isAdmin
      }
    }
  },
  methods: {
    ...mapActions({
      showConfirmation: 'confirmation/confirmationValue'
    }),
    dialSubOrder(item, type) {
      if (type === 'sale') {
        this.$refs.dialprodsale.open(item.pk)
      } else {
        this.$refs.dialprodpurchase.open(item.pk)
      }
    },
    async getOrderProduct() {
      if (
        this.order !== null &&
        this.order !== '' &&
        this.order !== undefined
      ) {
        try {
          this.loading = true
          let response =
            this.type === 'sale'
              ? await this.$api.sale.product.show({
                  pk: this.order,
                  opt: {
                    params: {
                      order: true
                    }
                  }
                })
              : await this.$api.purchase.product.show({
                  pk: this.order,
                  opt: {
                    params: {
                      order: true
                    }
                  }
                })
          this.products = response.data
          this.updateOwn = true
          this.$emit('update:cartlist', this.products)
        } finally {
          this.loading = false
          this.$store.dispatch('utils/createdsuborder', false)
        }
      }
    },
    remove(id) {
      this.showConfirmation({
        show: true,
        model: this.$t('shop_list'),
        title: this.$tc('delete', 1),
        type: 'error white--text',
        content: this.$t('confirm_delete'),
        alert: `${this.$t('success', {
          model: this.title,
          action: this.$tc('delete', 2)
        })}`,
        confirm: () =>
          this.type === 'sale'
            ? this.$api.sale.product.remove({ pk: id })
            : this.$api.purchase.product.remove({ pk: id })
      })
    }
  },
  watch: {
    getCreateSuborder: {
      handler(val) {
        if (val) {
          this.getOrderProduct()
        }
      }
    },
    cartlist: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.updateOwn) {
          this.getOrderProduct()
        }
      }
    },
    value: {
      immediate: true,
      handler() {}
    },
    order: {
      immediate: true,
      handler(val) {
        if (val !== '' && val !== null && val !== undefined) {
          this.getOrderProduct()
        }
      }
    },
    status: {
      immediate: true,
      handler() {}
    },
    products(val) {
      let sub = 0
      let tax = 0
      for (var i in val) {
        sub += Number(val[i].subtotal)
        tax += Number(val[i].tax)
      }
      this.tax = tax.toFixed(2)
      this.subtotal = sub.toFixed(2)
      this.total = (Number(sub) + Number(tax)).toFixed(2)
      this.$emit('update:subtotalOrder', this.subtotal)
      this.$emit('update:taxOrder', this.tax)
      this.$emit('update:totalOrder', this.total)
    }
  }
}
</script>
<style lang="sass">
$a1: rgba(0, 0, 0, 0.2)
$a2: rgba(0, 0, 0, 0.14)
$a3: rgba(0, 0, 0, 0.12)

.shoplist--fixed-button
  // --------------------------
  // Botón flotante del carrito
  // --------------------------
  position: fixed
  bottom: 45px
  right: calc(2vw + 60px)

.shoplist--card
  // -------------------------
  // Card flotante del carrito
  // -------------------------
  position: fixed
  bottom: 45px
  right: 16px
  overflow-y: auto
  overflow-x: hidden
  z-index: 5
  max-height: 74vh
  width: calc(97vw - 16px)
  @media (width >= 600px)
    max-height: 72vh
    width: calc(80vw - 16px)
  @media (width >= 960px)
    width: calc(33vw - 16px)

.shoplist--title
  // ------------------------------
  // Título de la lista del carrito
  // ------------------------------
  font-size: 1.25rem
  letter-spacing: 0.0125rem
  line-height: 2rem
  font-weight: 700
  color: var(--v-main-base)
  justify-content: space-between
  padding: 0
  padding-bottom: 16px
  margin-bottom: 16px
  border-bottom: 1px solid rgba(0,0,0,.1)
  @media (width >= 600px)
    font-size: 1.5rem
    display: flex

.shoplist--text
  // ---------------------------------------
  // Contenedor de los items de la lista del carrito
  // ---------------------------------------
  border-bottom: 1px solid rgba(0,0,0,.1)
  padding: 0
  padding-bottom: 16px
  margin-bottom: 16px

.shoplist--tags
  // ------------------------------------------
  // Tags del contenido de la lista del carrito
  // ------------------------------------------
  margin-top: 0
  margin-bottom: 0
  color: var(--v-main-base)
  font-weight: 700
  display: none
  @media (width >= 600px)
    display: grid
    grid-template-columns: 40% 20% 20% 20%

.shoplist--content
  // ---------------------------------
  // Contenido de la lista del carrito
  // ---------------------------------
  overflow-y: auto
  overflow-x: hidden
  max-height: 35vh
  @media (width >= 600px)
    max-height: 40vh

.shoplist--content-item
  // -------------------------------------------
  // Items del contenido de la lista del carrito
  // -------------------------------------------
  display: flex
  flex-direction: column
  align-items: left
  &:hover
    cursor: pointer
  @media (width >= 600px)
    display: grid
    grid-template-columns: 40% 20% 20% 20%
    text-align: right
    align-items: center

.shoplist--content-tags
  // --------------------------------------------------------------
  // Tags del contenido de la lista del carrito (solo para < 600px)
  // --------------------------------------------------------------
  color: var(--v-main-base)
  font-weight: 700
  @media (width >= 600px)
    display: none

.theme--light
  .v-sheet.shoplist--card
    box-shadow: 0px 3px 5px -1px $a1, 0px 5px 8px 0px $a2, 0px 1px 14px 0px $a3
  .shoplist--content-item
    &:hover
      background-color: rgba(0, 0, 0, .125)
.theme--dark
  .v-sheet.shoplist--card
    border: 1px solid rgba(132, 132, 132, 0.29)
    .shoplist--title, .shoplist--text
      border-color: rgba(132, 132, 132, 0.29)
  .shoplist--content-item
    &:hover
      background-color: rgba(255, 255, 255, .125)
</style>
